import React from "react";
import { Link, useHistory } from "react-router-dom";

const MenuItem = (props) => {
    let history = useHistory().location.pathname;

    return (
        <Link
            to={{ pathname: props.to, title: props.title }}
            className={`block p-3 font-bahij-bold text-lg lg:mt-3 ${props.className || ""} ${history === props.to ? "text-red-800" : "text-gray-800"}`}
            onClick={props.handleOnMenuClose}>
            {props.title}
        </Link>
    );
};

export default MenuItem;
