import { writeStorage } from "@rehooks/local-storage";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import useLocalStorage from "@rehooks/local-storage";

const LogoutView = () => {
    let history = useHistory();
    const [, setLoginState] = useLocalStorage("token");

    const logout = () => {
        // writeStorage("token", null);
        // writeStorage("admin", null);
        setLoginState(null);
        localStorage.clear();
        history.push("/");
    };

    useEffect(logout, []);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );
};

export default LogoutView;
