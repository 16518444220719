import Label from "./Label";
import React, { useState } from "react";
import ChangePassword from "./ChangePassword";

const UserCard = ({ user, saveEditUser }) => {
    const [showEditeUser, setShowEditeUser] = useState(false);

    return (
        <div className="flex bg-gray-50 flex-col self-start bg-one shadow-xl my-2 rounded-2xl px-2 md:px-4  py-2">
            <div className="flex flow-row items-center justify-between ">
                <span className="font-bahij-bold text-red-400 text-xl">{user.admin ? "مدير" : "مستخدم"}</span>
                <div onClick={() => setShowEditeUser(!showEditeUser)} className="cursor-pointer">
                    <span className="material-icons text-green-500 text-4xl">manage_accounts</span>
                </div>
            </div>

            {user.frozen && <p className="font-bahij-bold text-r">الحساب معطل</p>}
            {showEditeUser ? (
                <ChangePassword user={user} onEnd={saveEditUser} hide={() => setShowEditeUser(false)} />
            ) : (
                <>
                    <Label title="الإسم الصريح" discription={user.name} />
                    <Label title="إسم تسجيل الدخول" discription={user.logName} />
                </>
            )}
        </div>
    );
};

export default UserCard;
