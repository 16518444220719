import React from "react";
// import Label from "./Label";

const Label = ({ discription, title }) => (
    <p className="font-bahij-normal text-gray-800 text-lg text-tow p-1">
        {title} : <span className=" text-gray-800 font-bahij-bold">{discription}</span>
    </p>
);

const BusinessessCard = ({ items, onClick }) =>
    items.map((item, index) => (
        <div key={index} onClick={() => onClick(item.title, item.id)} className="bg-gray-50 shadow-xl cursor-pointer m-2 rounded-2xl px-4 py-2 self-start">
            <p className="font-bahij-bold text-red-400 text-xl text-tow p-1">{item.title}</p>
            <Label title="النوع" discription={item.businessType} />
            <Label title="العنوان" discription={item.address} />
        </div>
    ));

export default BusinessessCard;

const a = {
    id: "7d22d749-e082-44b8-9c6b-28be0198154a",
    title: "مذخر الغري drugstore",
    address: "النجف",
    businessType: "صيدلية",
};
