import React from "react";
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({ component: Component, ...rest }) => {
    let token = localStorage.getItem("token");
    let auth = !!token;

    return <Route {...rest} render={(props) => (auth === true ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default GuardedRoute;
