import { API } from "../API/remoteAPI";
import RowFlex from "../Components/RowFlex";
import ColumnFlex from "../Components/ColumnFlex";
import SearchForm from "../Components/SearchForm";
import React, { useEffect, useState } from "react";
import TransfersCard from "../Components/TransfersCard";
import LoaderComponent from "../Components/LoaderComponent";
import InfiniteScrollList from "../Components/InfiniteScrollList";

const TransfersView = () => {
    const [accounts, setAccounts] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [businesses, setBusinesses] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [totoleCount, setTotoleCount] = useState(0);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const fetchTransfers = (clear) => {
        clear && setIsLoading(true);
        const data = {
            searchText,
            currencyId: 0,
            companyId,
            offset: clear ? 0 : accounts.length,
        };
        API.post("OldTransfers/getTransfers", data).then(({ data }) => {
            setAccounts(clear ? data.accounts : [...accounts, ...data.accounts]);
            setTotoleCount(data.count);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        API.post("/OldTransfers/getCompanies").then(({ data }) => setBusinesses(data));
    }, []);

    useEffect(() => {
        fetchTransfers(true);
    }, [companyId]);

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <div>
            <ColumnFlex>
                <p className="font-bahij-bold text-gray-800 mb-4 mr-2 text-xl">
                    عدد الحوالات الكلي :<span className="text-red-400 font-bahij-bold"> {totoleCount}</span>
                </p>
                <SearchForm
                    value={searchText}
                    placeholder="بحث بالحوالات"
                    setValue={setSearchText}
                    onSubmit={() => fetchTransfers(true)}
                    valueChanged={(e) => setSearchText(e.target.value)}
                />
                {businesses.length > 0 && (
                    <RowFlex className="w-full md:w-1/2 lg:w-1/3 border py-1 px-4 shadow-lg bg-gray-50 rounded-2xl">
                        <select className="flex-1 text-my-gray font-bahij-bold" value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                            <option className="font-bahij-bold" value="0">
                                كل الشركات
                            </option>
                            {businesses.map((element) => (
                                <option className="font-bahij-bold" value={element.id} key={element.id}>
                                    {element.name}
                                </option>
                            ))}
                        </select>
                    </RowFlex>
                )}
            </ColumnFlex>

            <InfiniteScrollList data={accounts} fetchData={fetchTransfers} canLoadMore={canLoadMore} renderData={(item) => <TransfersCard item={item} />} />
        </div>
    );
};

export default TransfersView;
