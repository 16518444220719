import React, { useEffect, useState } from "react";
import { useGlobalState } from "../Hooks/UseGlobaleState";
import ColumnFlex from "./ColumnFlex";
import Grid from "./Grid";
import MaterialIcon from "./MaterialIcon";
// import NoData from "./NoData";

const InfiniteScrollList = ({
  fetchData,
  data,
  renderData,
  container = null,
  ...props
}) => {
  const [yScrollData] = useGlobalState("yScrollData");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [previousYScrollData, setPreviousYScrollData] = useState(null);
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);

  const scrollToTop = () => document.body.scrollTo(0, 0);

  const getOwnerHistory = () => {
    let ownerHistory = localStorage.getItem("history");
    return ownerHistory === null ? [] : JSON.parse(ownerHistory);
  };

  const getHistoryWithUpdatedEntry = (ownerHistory, last, newYValue) => {
    let actionSplit = last.action.split(" ");
    let previousValue = parseInt(actionSplit[actionSplit.length - 1]);
    last.action = `scrolled ${newYValue + previousValue}`;
    return [
      ...ownerHistory.filter((x) => x.createdAt !== last.createdAt),
      last,
    ];
  };

  const getHistoryWithNewEntry = (ownerHistory, ownerId, newYValue) => {
    ownerHistory.push({
      ownerId: ownerId,
      createdAt: new Date().toISOString(),
      action: `scrolled ${newYValue}`,
    });
    return ownerHistory;
  };

  const getUpdatedHistory = (ownerHistory, ownerId, newYValue) => {
    let last =
      ownerHistory.length > 0 ? ownerHistory[ownerHistory.length - 1] : null;
    return last && last.action.startsWith("scrolled")
      ? getHistoryWithUpdatedEntry(ownerHistory, last, newYValue)
      : getHistoryWithNewEntry(ownerHistory, ownerId, newYValue);
  };

  const updateOwnerHistory = (y) => {
    let owner = localStorage.getItem("owner");
    if (owner === null) return;
    owner = JSON.parse(owner);
    let ownerHistory = getOwnerHistory();
    ownerHistory = getUpdatedHistory(ownerHistory, owner.id, y);
    localStorage.setItem("history", JSON.stringify(ownerHistory));
  };

  useEffect(() => {
    updateOwnerHistory(yScrollData.value);
    setIsScrollToTopVisible(yScrollData.percentage >= 0.05);
    if (
      previousYScrollData !== null &&
      yScrollData.percentage > 0.875 &&
      yScrollData.direction === 1
    ) {
      if (!isLoadingMore && fetchData) {
        fetchData();
        setIsLoadingMore(true);
      }
    }
    setPreviousYScrollData(yScrollData);
  }, [yScrollData]);

  useEffect(() => setIsLoadingMore(false), [data]);

  return !data || data.length < 1 ? (
    <div />
  ) : (
    // <NoData noDataMessage={props.noDataMessage} />
    <ColumnFlex className={`pb-24 ${props.className || ""}`}>
      <Grid>{data.map((item, index) => renderData(item, index))}</Grid>
      <div className="flex flex-row absolute bottom-4 right-4 h-16">
        <button
          className={`h-16 w-16 bg-red-400 rounded-full shadow-lg text-gray-100 font-bahij-bold text-center ${
            isScrollToTopVisible ? "bounce-in" : "bounce-out"
          }`}
          onClick={scrollToTop}
        >
          <MaterialIcon icon="arrow_upward" className="text-4xl" />
        </button>
        {props.bottomButtons}
      </div>
    </ColumnFlex>
  );
};

export default InfiniteScrollList;
