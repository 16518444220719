import React, { useState } from "react";
import Form from "../Components/Form";
import { useHistory } from "react-router";
import { API } from "../API/remoteAPI";
import ColumnFlex from "../Components/ColumnFlex";
import PasswordInput from "../Components/PasswordInput";
import { writeStorage } from "@rehooks/local-storage";

const ChangePasswordView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    let history = useHistory();

    const changePassword = () => {
        if (isLoading) return;
        if (!oldPassword) {
            alert("الرجاء كتابة كلمة السر القديمة");
            return;
        } else if (!newPassword) {
            alert("الرجاء كتابة كلمة السر الجديدة");
            return;
        } else if (!newPasswordConfirm) {
            alert("الرجاء كتابة تأكيد كلمة السر الجديدة");
            return;
        } else if (newPassword !== newPasswordConfirm) {
            alert("كلمة السر الجديدة غير متطابقة");
            return;
        }
        setIsLoading(true);
        API.post("/User/ChangePassword", { oldPassword, newPassword })
            .then(() => {
                alert("تم تغيير كلمة السر بنجاح");
                history.push("/Logout");
            })
            .catch((e) => alert("خطأ"))
            .finally(() => setIsLoading(false));
    };

    return (
        <ColumnFlex>
            <p className="font-bahij-bold text-2xl text-gray-700 mb-2">تغيير كلمة السر</p>
            <Form onSubmit={changePassword} submitText="تغيير">
                <PasswordInput name="old-password" label="كلمة السر القديمة" value={oldPassword} valueChanged={(text) => setOldPassword(text)} />
                <PasswordInput name="new-password" label="كلمة السر الجديدة" value={newPassword} valueChanged={(text) => setNewPassword(text)} />
                <PasswordInput
                    name="new-password-confirm"
                    label="تأكيد كلمة السر الجديدة"
                    value={newPasswordConfirm}
                    valueChanged={(text) => setNewPasswordConfirm(text)}
                />
            </Form>
        </ColumnFlex>
    );
};

export default ChangePasswordView;
