const DataSource = "remote";

const MenuItems = [
    {
        title: "الحسابات",
        route: "/",
    },
    {
        title: "مبيعات المواد",
        route: "/sales",
    },

    {
        title: "الحوالات",
        route: "/Transfers",
    },
    {
        title: "تغيير كلمة السر",
        route: "/ChangePassword",
    },
];

export { DataSource, MenuItems };
