import { API } from "../API/remoteAPI";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ColumnFlex from "../Components/ColumnFlex";
import LoginInput from "../Components/LoginInput";
import { writeStorage } from "@rehooks/local-storage";
import LoaderComponent from "../Components/LoaderComponent";

const LoginView = () => {
    const [password, setPassword] = useState("");
    const [logName, setLogName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
    let history = useHistory();

    const login = (e) => {
        e.preventDefault();
        let data = {
            logName,
            password,
        };
        setShowLoginErrorMessage(false);
        setIsLoading(true);

        API.post("/User/login", data)
            .then((response) => {
                let token = response.data.token;
                let admin = response.data.admin;
                API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                writeStorage("token", token);
                writeStorage("admin", admin);
                history.push("/");
            })
            .catch((err) => {
                setIsLoading(false);
                localStorage.clear();
                setShowLoginErrorMessage(true);
                history.push("/login");
            });

        return false;
    };

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <ColumnFlex className="h-full">
            <form onSubmit={login} style={{ zIndex: 4 }} className="flex flex-col relative  md:mx-44">
                <div className="py-4 m-2 rounded-2xl bg-gray-50" style={{ boxShadow: "0px 2px 12px -4px #707070" }}>
                    <LoginInput
                        icon="account_circle"
                        name="phoneNumber"
                        value={logName}
                        valueChanged={(e) => setLogName(e)}
                        placeholder="اسم المستخدم"
                        type="text"
                    />
                    <LoginInput
                        icon="lock_open"
                        name="password"
                        value={password}
                        valueChanged={(e) => setPassword(e)}
                        placeholder="كلمة المرور"
                        type="password"
                    />
                </div>
                <input
                    type="submit"
                    value="تسجيل الدخول"
                    style={{ boxShadow: "0px 2px 12px -2px #707070" }}
                    className="self-center mt-4 px-4 text-xl py-2 text-center font-Boutros-Bold rounded-3xl text-green-400 text-white cursor-pointer"
                />
            </form>
            {showLoginErrorMessage && <p className="mt-8 font-Boutros-Medium text-red-700 text-3xl text-center">خطأ في اسم المستخدم او كلمة السر</p>}
        </ColumnFlex>
    );
};

export default LoginView;
