import React, { useEffect, useState } from "react";
import MaterialIcon from "./MaterialIcon";
import RowFlex from "./RowFlex";

const SearchForm = (props) => {
    const [clear, setClear] = useState(false);
    const submit = (e) => {
        e.preventDefault();
        props.onSubmit();
        return false;
    };
    const clearSearchValue = () => {
        setClear(true);
        props.setValue("");
    };
    useEffect(() => {
        if (props.value === "") {
            if (clear) {
                setClear(false);
                props.onSubmit();
            }
        }
    }, [props.value]);
    return (
        <RowFlex className="items-center mb-1">
            <form
                onSubmit={submit}
                className={`flex flex-row w-full md:w-1/2 lg:w-1/3 text-my-gray border pr-4 shadow-lg bg-gray-50 rounded-2xl ${props.className}`}>
                <RowFlex className="flex-1">
                    <p className="w-28 font-bahij-bold self-center">{props.placeholder} :</p>
                    <input
                        type="text"
                        className="focus:outline-none text-lg flex-1 font-Boutros-Medium py-1 rounded-2xl font-bahij-bold"
                        value={props.value}
                        placeholder={props.placeholder}
                        onChange={props.valueChanged}
                    />
                    {props.value ? (
                        <div onClick={clearSearchValue} className="cursor-pointer pl-2">
                            <MaterialIcon icon="close" className="text-2xl text-red-600" />
                        </div>
                    ) : (
                        <p className="w-6" />
                    )}
                </RowFlex>
            </form>
        </RowFlex>
    );
};

export default SearchForm;
