import React from "react";
import RowFlex from "./RowFlex";
import moment from "moment";
import MaterialIcon from "./MaterialIcon";

const Date = ({ date, className }) => {
  return (
    <RowFlex className={`items-center ${className}`}>
      {moment(date).format(" YYYY-MM-DD / hh:mmA")}
      <MaterialIcon icon="calendar_today" className="pr-2 text-xs" />
    </RowFlex>
  );
};

export default Date;
