import React from "react";

const FormSubmit = ({ value, bgClass = "bg-green-500", ...props }) => {
  return (
    <input
      type="submit"
      className={`${
        props.className || "rounded-3xl"
      } ${bgClass} self-center lg:self-start text-white text-lg font-bahij-bold px-4 py-2 mt-4 shadow-lg hover:shadow-md cursor-pointer print:hidden`}
      value={value || "حفظ"}
    />
  );
};

export default FormSubmit;
