import Button from "./Button";
import TextInput from "./TextInput";
import React, { useState } from "react";

const ChangePassword = ({ onEnd, hide, user }) => {
  const [name, setName] = useState(user?.name || "");
  const [frozen, setFrozen] = useState(false);
  const [password, setPassword] = useState("");
  const [logName, setLogName] = useState(user?.logName || "");
  const [confirmPassward, setConfirmPassward] = useState("");

  const handleSave = () => {
    if (
      (password !== "" || confirmPassward !== "") &&
      password !== confirmPassward
    )
      alert("كلمة السر غير متطابقة");
    else if (name === "") alert("الرجاء ادخال الإسم الصريح");
    else if (logName === "") alert("الرجاء ادخال إسم تسجيل الدخول");
    else
      onEnd(
        user
          ? { id: user.id, name, logName, frozen, password }
          : { name, logName, password }
      );
  };

  const close = () => {
    setFrozen(false);
    setPassword("");
    setConfirmPassward("");
    setName(user?.name || "");
    setLogName(user?.logName || "");
    hide();
  };

  return (
    <div>
      <TextInput
        textWidth="w-36"
        onChange={(val) => setName(val)}
        placeholder="الإسم الصريح"
        value={name}
      />
      <TextInput
        textWidth="w-36"
        onChange={(val) => setLogName(val)}
        placeholder="إسم تسجيل الدخول"
        value={logName}
      />
      <TextInput
        textWidth="w-36"
        label="كلمة السر"
        value={password}
        onChange={(val) => setPassword(val)}
        placeholder={user ? "اترك الحقل فارغ لعدم التغيير" : "كلمة السر"}
      />
      <TextInput
        onChange={(val) => setConfirmPassward(val)}
        textWidth="w-36"
        label="تاكيد كلمة السر"
        value={confirmPassward}
        placeholder={user ? "اترك الحقل فارغ لعدم التغيير" : "تاكيد كلمة السر"}
      />

      {user && (
        <div
          onClick={() => setFrozen(!frozen)}
          className="items-center flex flex-row self-start cursor-pointer"
        >
          <p className="font-bahij-normal text-gray-800 text-lg text-tow w-36 text-left ml-3">
            تعطيل الحساب
          </p>
          <span
            className={`material-icons text-3xl ${
              frozen ? "text-gg" : "text-gray-500"
            }`}
          >
            {frozen ? "check_box" : "check_box_outline_blank"}
          </span>
        </div>
      )}
      <div className="flex flex-row my-4 justify-around">
        <Button onClick={handleSave} title="حفظ" />
        <Button onClick={close} title="الغاء" bg="red-500" />
      </div>
    </div>
  );
};

export default ChangePassword;
