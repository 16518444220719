import React from "react";
import moment from "moment";
// import Label from "./Label";
import Formatter from "./Formatter";
import ColumnFlex from "./ColumnFlex";

const Label = ({ discription, title }) => (
    <p className="font-bahij-normal text-gray-800 text-lg  p-1 mr-2">
        {title} : <span className="font-bahij-bold text-rr">{discription}</span>
    </p>
);

const ListView = ({ item }) => {
    return (
        <ColumnFlex className=" bg-gray-50 shadow-xl my-2 rounded-2xl">
            <p className="font-bahij-normal bg-gray-50 border-b-2 border-gray-800 text-gray-800 p-2 text-lg   rounded-t-2xl">
                <span className=" text-gray-800 font-bahij-bold">الشركة : {item.businessName}</span>
                <br />
                <span className="  font-bahij-bold">المادة :{item.itemName}</span>
            </p>
            {/* <Label title="الشركة" discription={item.businessName} /> */}
            <Label title="العميل" discription={item.clientName} />
            {/* <Label title="المادة" discription={item.itemName} /> */}
            <Label title="السعر" discription={Formatter(item.salePrice, item.currencyId)} />
            <Label title="العدد" discription={item.number} />
            <Label title="رقم القائمة" discription={item.opId2} />
            <Label title="التاريخ" discription={moment(item.date).format("YYYY/MM/DD")} />
        </ColumnFlex>
    );
};

export default ListView;
