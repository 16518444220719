import { API } from "../API/remoteAPI";
import Button from "../Components/Button";
import { useHistory } from "react-router";
import RowFlex from "../Components/RowFlex";
import SearchForm from "../Components/SearchForm";
import ColumnFlex from "../Components/ColumnFlex";
import React, { useState, useEffect } from "react";
import AccountCard from "../Components/AccountCard";
import BusinessessCard from "../Components/BusinessessCard";
import LoaderComponent from "../Components/LoaderComponent";
import InfiniteScrollList from "../Components/InfiniteScrollList";
let canLoadMoreItems = false;

const AccountsView = () => {
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [businesses, setBusinesses] = useState([]);
    const [accountName, setAccountName] = useState(JSON.parse(localStorage.getItem("accountName")) || "");
    const [selectedBussiness, setSelectedBussiness] = useState(JSON.parse(localStorage.getItem("selectedBussiness")) || { id: "" });
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [hidebusinesses, setHidebusinesses] = useState(true);
    const [businessSearchTrim, setBusinessSearchTrim] = useState(JSON.parse(localStorage.getItem("businessSearchTrim")) || "");
    const [businessesLoading, setBusinessesLoading] = useState(true);
    const [totoleAccountsCount, setTotoleAccountsCount] = useState(0);
    const [totalBusinesessCount, setTottalBusinesessCount] = useState(0);
    const history = useHistory();

    const fetchAccounts = (refreish) => {
        let offset = refreish ? 0 : accounts.length;
        if (!canLoadMore && offset > 0) return;
        localStorage.setItem("accountName", JSON.stringify(accountName));
        refreish && setIsLoading(true);
        const dataToSend = {
            accountName,
            bussinessId: selectedBussiness?.id,
            offset,
        };
        API.post("/Account", dataToSend)
            .then(({ data }) => {
                setCanLoadMore(data.accounts.length >= 25);
                offset > 0 ? setAccounts([...accounts, ...data.accounts]) : setAccounts(data.accounts);
                setTotoleAccountsCount(data.count);
            })

            .finally(() => setIsLoading(false));
    };

    useEffect(() => getBusinesses(true), []);
    useEffect(() => {
        localStorage.setItem("selectedBussiness", JSON.stringify(selectedBussiness));
        fetchAccounts(true);
    }, [selectedBussiness]);

    const getBusinesses = (clear = false) => {
        clear && setBusinessesLoading(true);
        localStorage.setItem("businessSearchTrim", JSON.stringify(businessSearchTrim));
        API.post("/Account/getBusinesses", {
            name: businessSearchTrim,
            offset: clear ? 0 : businesses?.length,
        })
            .then(({ data }) => {
                setBusinesses(clear ? data.businesses : [...businesses, ...data.businesses]);
                setTottalBusinesessCount(data.count);
                canLoadMoreItems = data.businesses.length >= 25;
            })
            .finally(() => setBusinessesLoading(false));
    };
    const itemsScroll = ({ target }) =>
        canLoadMoreItems && target.scrollHeight - target.scrollTop < target.clientHeight + 150 && ((canLoadMoreItems = false), getBusinesses(false));

    const [isShowen, setIsShowen] = useState(false);
    useEffect(() => {
        setTimeout(() => setIsShowen(!hidebusinesses), hidebusinesses ? 400 : 0);
    }, [hidebusinesses]);

    return (
        <>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <ColumnFlex className="">
                    <p className="font-bahij-bold text-gray-800 mb-4 mr-2 text-xl">
                        عدد النتائج :<span className="text-rr font-bahij-bold">{totoleAccountsCount}</span>
                    </p>
                    <SearchForm
                        value={accountName}
                        placeholder="اسم الحساب"
                        setValue={setAccountName}
                        onSubmit={() => fetchAccounts(true)}
                        valueChanged={(e) => setAccountName(e.target.value)}
                    />
                    {businesses.length > 0 && (
                        <RowFlex>
                            <Button title={selectedBussiness?.title || "كل الشركات"} onClick={() => setHidebusinesses(false)} />
                            {selectedBussiness?.id && <Button bg="red-400" title="الغاء الفلترة" onClick={() => setSelectedBussiness({ id: "" })} />}
                        </RowFlex>
                    )}
                    <InfiniteScrollList
                        data={accounts}
                        fetchData={fetchAccounts}
                        canLoadMore={canLoadMore}
                        renderData={(item, index) => <AccountCard item={item} index={index} key={index} />}
                    />
                </ColumnFlex>
            )}
            {isShowen && (
                <div onScroll={itemsScroll} style={{ zIndex: 3 }} className={`absolute ${!hidebusinesses && "bgTransfrom"} inset-0 bg-cardBg  `}>
                    <div
                        className={`bg-gray-50 absolute left-0 right-0 overflow-y-hidden mx-2 md:mx:24 lg:mx-20  rounded-2xl px-2 pt-2 ${
                            hidebusinesses ? "popdown" : "popup"
                        }`}>
                        <p className="font-bahij-bold text-gray-800 my-2 mr-2 text-xl">
                            عدد الشركات الكلي :<span className="text-rr font-bahij-bold">{totalBusinesessCount}</span>
                        </p>
                        <SearchForm
                            placeholder="الشركة "
                            value={businessSearchTrim}
                            setValue={setBusinessSearchTrim}
                            onSubmit={() => getBusinesses(true)}
                            valueChanged={(e) => setBusinessSearchTrim(e.target.value)}
                        />
                        <button
                            onClick={() => setHidebusinesses(true)}
                            className="absolute right-4 bottom-10 bg-red-400 text-white text-2xl h-16 w-16 rounded-full ">
                            الغاء
                        </button>

                        <div onScroll={itemsScroll} className=" grid md:grid-cols-2 overflow-y-scroll h-screen lg:grid-cols-3">
                            {businessesLoading ? (
                                <LoaderComponent />
                            ) : (
                                <BusinessessCard
                                    onClick={(title, id) => {
                                        setSelectedBussiness({ title, id });
                                        setHidebusinesses(true);
                                    }}
                                    items={businesses}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountsView;
