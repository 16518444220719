import React from "react";
import RowFlex from "./RowFlex";

const TextInput = ({ value, onChange, placeholder, textWidth, label }) => {
    return (
        <RowFlex className="items-center m-1 ">
            <p className={`font-bahij-normal text-gray-800 text-lg text-tow text-left ${textWidth}`}>{label || placeholder}</p>
            <input
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className="px-1 mx-2 font-bahij-bold border-2 p-1 rounded-md flex-1 "
            />
        </RowFlex>
    );
};

export default TextInput;
