import { API } from "../API/remoteAPI";
import UserCard from "../Components/UserCard";
import React, { useEffect, useState } from "react";
import ChangePassword from "../Components/ChangePassword";
import LoaderComponent from "../Components/LoaderComponent";
import InfiniteScrollList from "../Components/InfiniteScrollList";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [animationTimer, setAnimationTimer] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const fetchUser = () => {
        API.get("/User").then((response) => {
            setUsers(response.data);
            setIsLoading(false);
        });
    };
    useEffect(() => fetchUser(true), []);

    const saveEditUser = (dataToSend) => {
        setIsLoading(true);
        API.post("/User/Update", dataToSend).then(() => {
            alert("تم الحفظ");
            fetchUser(true);
        });
    };

    const handleAddUser = (dataToSend) => {
        setIsLoading(true);
        API.post("/User/Add", dataToSend).then(() => {
            alert("تمت الاضافة");
            fetchUser(true);
        });
    };

    useEffect(() => {
        !showAdd
            ? setTimeout(() => {
                  setAnimationTimer(false);
              }, 280)
            : setAnimationTimer(true);
    }, [showAdd]);

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <>
            <div className="md:w-1/2 lg:w-1/3">
                <div className={`bg-gray-50 rounded-2xl md:px:4 py-2 shadow-md overflow-hidden ${showAdd ? "popup-in" : "popup-out"}`}>
                    {animationTimer ? (
                        <ChangePassword hide={() => setShowAdd(!showAdd)} onEnd={handleAddUser} />
                    ) : (
                        <div onClick={() => setShowAdd(!showAdd)} className=" flex flex-row items-center rounded-2xl px-4 cursor-pointer">
                            <span className="material-icons text-blue-900 text-4xl">person_add</span>
                            <p className="font-bahij-bold px-2 text-blue-900">اضافة مستخدم جديد</p>
                        </div>
                    )}
                </div>
            </div>
            <InfiniteScrollList data={users} fetchData={fetchUser} renderData={(user) => <UserCard user={user} saveEditUser={saveEditUser} key={user.id} />} />
        </>
    );
};

export default Users;
