import moment from "moment";
import React from "react";
import ColumnFlex from "./ColumnFlex";
import Formatter from "./Formatter";

const Label = ({ discription, title }) => (
    <p className="font-bahij-bold text-gray-800  text-tow p-1">
        {title} :<span className=" text-red-400 font-bahij-bold">{discription}</span>
    </p>
);

const TransfersCard = ({ item }) => (
    <ColumnFlex key={item.id} className="shadow-xl bg-gray-50 my-2 rounded-2xl px-4 py-2">
        <Label title="الشركة" discription={item.cName} />
            <Label title="التاريخ" discription={moment(item.d).format("YYYY/MM/DD")} />
            <Label title="العملة" discription={item.cuName} />
            <Label title="المبلغ" discription={Formatter(item.a)} />
            <Label title="المستلم" discription={item.r} />
            <Label title="المرسل" discription={item.s} />
            <Label title="الجهة" discription={item.ds}/>
            <Label title="المصدر" discription={item.sc} />
    </ColumnFlex>
);

export default TransfersCard;
