import React from "react";
import Formatter from "./Formatter";
import ColumnFlex from "./ColumnFlex";
import { Link } from "react-router-dom";
const Label = ({ discription, title }) => (
    <p className="font-bahij-normal text-gray-800  text-tow p-1">
        {title} :<span className=" text-gray-600 font-bahij-bold">{discription}</span>
    </p>
);
const Balnce = ({ discription, title }) => (
    <p className="font-bahij-normal text-gray-800 text-center px-4 py-1" style={{ minWidth: 100 }}>
        <span className="font-bahij-bold text-rr">{discription}</span>
        <br />
        <span className=" text-gray-800 font-bahij-bold">{title}</span>
    </p>
);

const Balabces = () => <div></div>;

const AccountCard = ({ item }) => {
    return (
        <ColumnFlex className="shadow-xl bg-gray-50 my-2 rounded-2xl px-4 py-2">
            <Label title="الاسم" discription={item.name} />
            <Label title="الشركة" discription={item.businessName} />
            <Label title="عنوان الشركة" discription={item.businessAddress} />
            <Label title="النوع" discription={item.businessType} />
            <Label title="العنوان" discription={item.address} />
            <Label title="رقم الهاتف" discription={item.phoneNumber} />
            <div className="flex flex-row justify-between p-2 items-start">
                <Link className="border-2 rounded-xl border-gray-300" to={`/transactions/${item.id}/1`}>
                    <Balnce title={item.balances[0]?.state || "-"} discription={Formatter(item.balances[0]?.amount, 1)} />
                </Link>
                <Link className="border-2 rounded-xl border-gray-300" to={`/transactions/${item.id}/2`}>
                    <Balnce title={item.balances[1]?.state || "-"} discription={Formatter(item.balances[1]?.amount, 2)} />
                </Link>
            </div>
        </ColumnFlex>
    );
};

export default AccountCard;

const test = {
    id: "068d6457-434c-4aae-9441-1f828ebb16af",
    businessId: "9cf202eb-cbaa-4aa5-8cfa-7ca70c527de4",
    name: "شركة الصالح لأنتاج العصائر والمياه المعدنية",
    businessName: "نبع النهرين",
    businessAddress: "النجف الاشرف",
    businessType: "ماركت",
    phoneNumber: "",
    address: null,
    balances: [
        {
            id: null,
            accountId: null,
            amount: -8656150,
            currencyId: 1,
            state: "دائن / علينا",
        },
        {
            id: null,
            accountId: null,
            amount: 0,
            currencyId: 2,
            state: "",
        },
    ],
};
