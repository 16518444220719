import { API } from "../API/remoteAPI";
import RowFlex from "../Components/RowFlex";
import ListView from "../Components/ListView";
import InstallPWA from "../Components/InstallPWA";
import ColumnFlex from "../Components/ColumnFlex";
import React, { useState, useEffect } from "react";
import SearchForm from "../Components/SearchForm";
import LoaderComponent from "../Components/LoaderComponent";
import InfiniteScrollList from "../Components/InfiniteScrollList";
import { useHistory } from "react-router";
import Button from "../Components/Button";

const DashboardView = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const history = useHistory();
    const [itemName, setItemName] = useState("");
    const [businessId, setBusinessId] = useState(0);
    const [businesses, setBusinesses] = useState([]);
    const [clientName, setClientName] = useState("");

    const fetchData = (refreish) => {
        let offset = refreish ? 0 : data.length;
        if (!canLoadMore && offset > 0) return;

        refreish && setIsLoading(true);
        const dataToSend = {
            itemName,
            clientName,
            bussinessId: parseInt(businessId),
            offset,
        };
        API.post("/BillItems/search", dataToSend)
            .then((response) => {
                setCanLoadMore(response.data.length >= 25);
                offset > 0 ? setData([...data, ...response.data]) : setData(response.data);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) {
                    history.push("/logout");
                }
            })
            .finally(() => setIsLoading(false));
    };

    const init = async () => {
        let businessesResponse = await API.get("/Business");
        setBusinesses(businessesResponse.data);
        fetchData(true);
    };

    useEffect(() => init(), []);

    return (
        <>
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <ColumnFlex className="">
                    <SearchForm
                        value={itemName}
                        placeholder="اسم المادة"
                        setValue={setItemName}
                        onSubmit={() => fetchData(true)}
                        valueChanged={(e) => setItemName(e.target.value)}
                    />
                    <SearchForm
                        value={clientName}
                        placeholder="اسم العميل"
                        setValue={setClientName}
                        onSubmit={() => fetchData(true)}
                        valueChanged={(e) => setClientName(e.target.value)}
                    />
                    {businesses.length > 0 && (
                        <RowFlex className="w-full md:w-1/2 lg:w-1/3 border py-1 px-4 shadow-lg bg-gray-50 rounded-2xl">
                            {/* <p className="w-20 self-center text-left font-bahij-bold mx-2">الشركة:</p> */}
                            <select className="flex-1 text-my-gray font-bahij-bold" value={businessId} onChange={(e) => setBusinessId(e.target.value)}>
                                <option className="font-bahij-bold" value="0">
                                    كل الشركات
                                </option>
                                {businesses.map((element) => (
                                    <option className="font-bahij-bold" value={element.id} key={element.id}>
                                        {element.name}
                                    </option>
                                ))}
                            </select>
                        </RowFlex>
                    )}

                    <Button onClick={() => fetchData(true)} title="بحث" />

                    <InfiniteScrollList
                        data={data}
                        fetchData={fetchData}
                        canLoadMore={canLoadMore}
                        renderData={(item, index) => <ListView item={item} index={index} key={index} />}
                    />
                </ColumnFlex>
            )}
            <InstallPWA className="fixed bottom-0 left-0 right-0" />
        </>
    );
};

export default DashboardView;
