import React, { useState } from "react";
import MaterialIcon from "./MaterialIcon";
// import { isMobile } from "react-device-detect";
import RowFlex from "./RowFlex";
import ColumnFlex from "./ColumnFlex";

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
        return false;
    };

    return (
        <ColumnFlex className=" mb-4 mx-2 px-2 justify-center">
            <p className="text-my-gray font-bahij-bold text-xl">{props.label}</p>
            <RowFlex>
                <input
                    id={props.name}
                    required={true}
                    type={showPassword ? "text" : "password"}
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    className="text-my-gray font-bahij-bold rounded-md w-full text-xl shadow-lg p-1"
                    onChange={(e) => props.valueChanged(e.target.value)}
                />
                <MaterialIcon icon="lock_open" className="text-3xl" />
                {/* {isMobile && props.value && (
                    <div onClick={toggleShowPassword} className="self-center">
                        <MaterialIcon icon={showPassword ? "visibility" : "visibility_off"} className="mx-2 text-xl cursor-pointer" />
                    </div>
                )} */}
            </RowFlex>
        </ColumnFlex>
    );
};

export default PasswordInput;
