import useLocalStorage from "@rehooks/local-storage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// import BusinessAPI from "../../API/Business";
// import { HubAPI } from "../../API/remoteAPI";
import { MenuItems } from "../../consts";
import { useGlobalState } from "../../Hooks/UseGlobaleState";
import SideMenu from "./SideMenu";
import TopBar from "./TopBar";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleOnMenuOpen = (e) => setIsMenuOpen(true);
  const handleOnMenuClose = (e) => setIsMenuOpen(false);
  const [topBarTitle, setTopBarTitle] = useGlobalState("");
  const [menuItems, setMenuItems] = useState(MenuItems);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
  let history = useHistory();
  const [admin] = useLocalStorage("admin");

  const backButtonPressed = () => {
    if (isMenuOpen) setIsMenuOpen(false);
    else history.goBack();
  };
  const updateTopBarTitle = () => {
    let item = menuItems.find((x) => x.route === history.location.pathname);
    setIsBackButtonVisible(history.location.pathname !== "/");
    console.log(history);
    item?.title && setTopBarTitle(item.title);
  };

  useEffect(() => history.listen(updateTopBarTitle), [history]);

  useEffect(() => {
    admin &&
      setMenuItems([...menuItems, { title: "المستخدمون", route: "/user" }]);
  }, [admin]);

  return (
    <>
      <TopBar
        title={topBarTitle}
        goBack={backButtonPressed}
        toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
        isBackButtonVisible={isBackButtonVisible}
        handleOnMenuClose={handleOnMenuClose}
      />
      <SideMenu
        menuItems={menuItems}
        isMenuOpen={isMenuOpen}
        handleOnMenuOpen={handleOnMenuOpen}
        handleOnMenuClose={handleOnMenuClose}
      />
    </>
  );
};

export default Navigation;
