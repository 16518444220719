import React from "react";
import ColumnFlex from "./ColumnFlex";
import FormSubmit from "./FormSubmit";

const Form = (props) => {
    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(e);
        return false;
    };

    return (
        <form className={props.className} onSubmit={onSubmit}>
            <ColumnFlex className={props.flexClassName || ""}>
                {props.children}
                <FormSubmit value={props.submitText} fullWidth={props.submitFullWidth} className={props.submitClasses} />
            </ColumnFlex>
        </form>
    );
};

export default Form;
