import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { API } from "../API/remoteAPI";
import ColumnFlex from "../Components/ColumnFlex";
import Formatter from "../Components/Formatter";
import Date from "../Components/Date";
import LoaderComponent from "../Components/LoaderComponent";
import RowFlex from "../Components/RowFlex";
import InfiniteScrollList from "../Components/InfiniteScrollList";

const AccountTransactionsView = () => {
    const [transactions, setTransactions] = useState([]);
    const { accountId, currencyId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const fetchTransActions = (clear = false) => {
        API.post("/Account/getTransactions", {
            accountId,
            currencyId,
            offset: clear ? 0 : transactions.length,
        }).then((response) => {
            let arr = [];
            response.data.forEach((element) => {
                element.description = element.description.split(":");
                arr.push(element);
            });
            setTransactions(clear ? arr : [...transactions, ...arr]);
            setIsLoading(false);
        });
    };
    useEffect(() => {
        setIsLoading(true);
        fetchTransActions(true);
    }, [currencyId]);

    return isLoading ? (
        <LoaderComponent />
    ) : (
        <InfiniteScrollList
            data={transactions}
            fetchData={fetchTransActions}
            renderData={(bd) => (
                <ColumnFlex key={bd.id} className="justify-evenly m-2 bg-gray-50 rounded-2xl shadow-lg ">
                    <RowFlex className="border-b-2 border-rr">
                        <p className="text-center px-4 py-2 border-l border-rr font-bein-bold text-gray-4 text-xl">المبلغ</p>
                        <p className={`text-center flex-grow font-bold py-2 text-xl ${bd.amount >= 0 ? "text-blue-900" : "text-rr"}`}>
                            <span>{Formatter(bd.amount, currencyId)}</span>
                            {/* &nbsp; */}
                        </p>
                    </RowFlex>
                    <div className={`rounded-b-xl `}>
                        <p className="text-gray-4  px-2 py-1">
                            <span className="font-bein-bold">{bd.description[0] || "..."} :</span>
                            <span className="">{bd.description[1] || "..."}</span>
                        </p>
                        <Date date={bd.date} className={`px-2 py-1 float-left rounded-tr-2xl rounded-bl-2xl text-gray-4 font-semibold `} />
                    </div>
                </ColumnFlex>
            )}
        />
    );
};

export default AccountTransactionsView;
const test = {
    accountId: "068d6457-434c-4aae-9441-1f828ebb16af",
    amount: 9405000,
    balance: 0,
    currencyId: 1,
    date: "2021-04-05T15:40:58",
    description: " واصل من قائمة شراء 1/ للزبون :شركة الصالح لأنتاج العصائر والمياه المعدنية",
    id: "e2b8fcc9-3ba9-43be-b29d-5cf45e4546d5",
    opType: " شراء",
    status: "",
};
