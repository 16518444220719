import React from "react";

const Button = ({ onClick, title, bg = "green-500" }) => (
  <button
    onClick={onClick}
    className={`font-bahij-bold px-4 py-2 my-2 rounded-xl self-start mx-1 shadow-lg bg-${bg} text-white`}
  >
    {title}
  </button>
);

export default Button;
