import "./App.css";
import "./index.css";
import { useEffect } from "react";
import LoginView from "./Views/LoginView";
import InstallPWA from "./Components/InstallPWA";
import DashboardView from "./Views/DashboardView";
import useScrollInfo from "./Hooks/UseScrollInfo";
import GuardedRoute from "./Components/GuardedRoute";
import useLocalStorage from "@rehooks/local-storage";
import Navigation from "./Components/Navigation/Navigation";
import { store, useGlobalState } from "./Hooks/UseGlobaleState";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LogoutView from "./Views/LogoutView";
import ChangePasswordView from "./Views/ChangePasswordView";
import Users from "./Views/Users";
import AccountsView from "./Views/AccountsView";
import AccountTransactionsView from "./Views/AccountTransactionsView";
import TransfersView from "./Views/TransfersView";

store.init({ yScrollData: {} });
function App() {
    const [scrollInfo, setRef] = useScrollInfo();
    const [loginState] = useLocalStorage("token");
    const [, setYScrollData] = useGlobalState("yScrollData");
    useEffect(() => setYScrollData(scrollInfo.y), [scrollInfo]);

    return (
        <Router>
            <div id="outer-container" className="h-full">
                {loginState && <Navigation />}
                <div ref={setRef} id="page-wrap" className="container mx-auto px-2 lg:px-4 pt-20 h-screen">
                    <Switch>
                        <Route path="/login" exact component={LoginView} />
                        <Route path="/Logout/" exact component={LogoutView} />
                        <GuardedRoute path="/" exact component={AccountsView} />
                        <GuardedRoute path="/sales" exact component={DashboardView} />
                        <GuardedRoute path="/transactions/:accountId/:currencyId" exact component={AccountTransactionsView} />
                        <GuardedRoute path="/User" exact component={Users} />
                        <GuardedRoute path="/Transfers" exact component={TransfersView} />
                        <GuardedRoute path="/ChangePassword/" exact component={ChangePasswordView} />
                    </Switch>
                    <InstallPWA />
                </div>
            </div>
        </Router>
    );
}
export default App;
