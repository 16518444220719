import MenuItem from "./MenuItem";
// import ColumnFlex from "../ColumnFlex";
import { Link } from "react-router-dom";
// import { HubAPI } from "../../API/remoteAPI";
import { push as Menu } from "react-burger-menu";
import React, { useEffect, useState } from "react";
// import { writeStorage } from "@rehooks/local-storage";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import ColumnFlex from "../ColumnFlex";
const SideMenu = (props) => {
    const { width } = useWindowDimensions();
    const [isFullWidth, setIsFullWidth] = useState(width < 768);
    const [owner, setOwner] = useState([]);
    const [pfpImage, setPfpImage] = useState("https://hubcore.morabaaapps.com/files/d576ef63-0d0e-4e1c-8375-2efed9cd1d70.svg");
    // useEffect(() => {
    //     HubAPI.get("/owners/me").then((response) => {
    //         writeStorage("owner", response.data);
    //         setOwner(response.data);
    //     });
    // }, []);
    useEffect(() => setIsFullWidth(width < 768), [width]);

    // useEffect(
    //     () =>
    //         setPfpImage(
    //             owner && owner.image
    //                 ? `https://hubcore.morabaaapps.com${owner.image}`
    //                 : "https://hubcore.morabaaapps.com/files/d576ef63-0d0e-4e1c-8375-2efed9cd1d70.svg"
    //         ),
    //     [owner]
    // );

    return (
        <Menu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
            right
            isOpen={props.isMenuOpen}
            onClose={props.handleOnMenuClose}
            menuClassName="overflow-y-auto"
            className="top-12"
            width={isFullWidth ? "100%" : 300}
            customBurgerIcon={false}
            noOverlay={false}>
            <ColumnFlex className="items-center">
                {props.menuItems.map((item) => (
                    <MenuItem
                        className="border-b-2 border-gray-4 text-center w-40"
                        to={item.route}
                        key={item.route}
                        title={item.title}
                        handleOnMenuClose={() => props.handleOnMenuClose(null)}
                    />
                ))}
                <MenuItem to="/logout" title="تسجيل الخروج" handleOnMenuClose={() => props.handleOnMenuClose(null)} />
            </ColumnFlex>
        </Menu>
    );
};

export default SideMenu;
