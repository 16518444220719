import React from "react";

const Grid = (props) => {
    const cols = `grid-cols-${props.cols || "1"}`;
    const mdCols = props.mdCols ? `md:grid-cols-${props.mdCols}` : "md:grid-cols-1";
    const lgCols = props.lgCols ? `lg:grid-cols-${props.lgCols}` : "lg:grid-cols-2";
    const xlCols = props.xlCols ? `lg:grid-cols-${props.xlCols}` : "xl:grid-cols-3";
    const gridGap = `gap-${props.gap || "2"}`;
    return (
        <div className={`grid auto-rows-auto ${gridGap} md:gap-2 lg:gap-3 xl:gap-3 ${cols} ${mdCols} ${xlCols} ${lgCols} ${props.className || ""}`}>
            {props.children}
        </div>
    );
};

export default Grid;
