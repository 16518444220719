import { useState } from "react";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import MaterialIcon from "../MaterialIcon";
// import Notifications from "../../API/Notifications";

const TopBar = (props) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(/img/morabaa/bg.png)`,
          zIndex: 2,
        }}
        className="absolute flex flex-row p-2 h-12 bg-gray-700 justify-between right-0 left-0 top-0 items-center"
      >
        <div
          className="font-bahij-bold text-white text-xl mx-2 p-2 cursor-pointer"
          onClick={props.toggleMenu}
        >
          <MaterialIcon icon="menu" className="align-middle text-4xl " />
        </div>
        <p className="font-bahij-bold text-right flex-1 text-gray-50 text-xl mx-2 truncate">
          {props.title}
        </p>

        {props.isBackButtonVisible && (
          <div
            className="font-bahij-bold text-white text-xl mx-2 p-2 cursor-pointer"
            onClick={props.goBack}
          >
            <MaterialIcon icon="arrow_back" className="align-middle" />
          </div>
        )}
      </div>
    </>
  );
};

export default TopBar;
