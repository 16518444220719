import React from "react";
import ColumnFlex from "./ColumnFlex";
import MaterialIcon from "./MaterialIcon";
import RowFlex from "./RowFlex";

const LoginInput = (props) => (
    <ColumnFlex className="mb-4 mx-2 px-2 border-b border-gray-300">
        <p className="text-my-gray font-Boutros-Medium text-xl">{props.placeholder}</p>
        <RowFlex>
            <input
                id={props.name}
                type={props.type}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onChange={(e) => props.valueChanged(e.target.value)}
                className="text-my-gray font-Boutros-Medium w-full text-xl"
            />
            {/* <img src="/img/icons/user.png" type="image/png" className="w-7 m-1" /> */}
            <MaterialIcon icon={props.icon}  />
        </RowFlex>
    </ColumnFlex>
);

export default LoginInput;
