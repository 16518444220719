import axios from "axios";
const APIRoot = "https://api.njfis.com/";

const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        // headers: {
        //     "Content-Type": "application/json",
        //     "App-Package": "com.morabaa.my",
        // },
    });
    instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return instance;
};

export const updateToken = (token) => {
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const API = getAxiosInstance(APIRoot);
