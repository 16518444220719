import React from "react";
import Loader from "react-js-loader";
const LoaderComponent = (props) => {
    return (
        <div className="relative">
            <div className=" relative top-52">
                <Loader type="spinner-circle" bgColor={"#505050"} size={100} />
            </div>
        </div>
    );
};
export default LoaderComponent;
